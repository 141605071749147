// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide {
    position: relative;
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-position: center;
}

.slide-content {
    position: absolute;
    border-radius: 15px;
    bottom: 20px;
    left: 20px;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/styles/MainBanner.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,UAAU;IACV,YAAY;AAChB","sourcesContent":[".slide {\r\n    position: relative;\r\n    width: 100%;\r\n    height: 80vh;\r\n    background-size: cover;\r\n    background-position: center;\r\n}\r\n\r\n.slide-content {\r\n    position: absolute;\r\n    border-radius: 15px;\r\n    bottom: 20px;\r\n    left: 20px;\r\n    color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
