
const Slides = [
    {
        image: 'https://scontent.xx.fbcdn.net/v/t1.6435-9/67953324_2388339854743650_7860918881970290688_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=f798df&_nc_ohc=dN1AXq_uV3UQ7kNvgHPKNd8&_nc_ht=scontent.xx&gid=Ash3KTPUjbpKL2kCHlioZ1J&oh=00_AYDTlTGxDzbcC06uprchADQ3btFHmSvQ7gym7z_zNaEQwQ&oe=66D05E9C',
        title: 'Esala Perahera',
        description: 'Witness the grandeur of the Esala Perahera, an annual procession that lights up the streets of Kandy with vibrant costumes, traditional dances, and majestic elephants.',
    },
    {
        image: 'https://miro.medium.com/v2/resize:fit:2000/1*UTMYLJ4O9xJS89SazTUBLw.jpeg',
        title: 'Kandy Lake',
        description: 'Relax by the serene Kandy Lake, a picturesque spot in the heart of the city perfect for a peaceful stroll or a moment of reflection.',
    }
];

export default Slides;
